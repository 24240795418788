import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Features from "../components/features";
import Content, { HTMLContent } from "../components/content";

import CallToAction from "../components/calltoaction";

import BackgroundImage from "gatsby-background-image";

const PrestationsPageTemplate = ({
  title,
  backgroundImageFile,
  prestations,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <BackgroundImage
        Tag="div"
        className="mb-10 bg-opacity-50 h-32 sm:h-64 bg-cover bg-no-repeat bg-center flex items-center content-center justify-center"
        fluid={backgroundImageFile.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <h1 className="text-gray-900 text-center text-3xl sm:text-6xl px-4 sm:px-6 bg-green-100 bg-opacity-75">
          {title}
        </h1>
      </BackgroundImage>
      <section className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <PageContent content={content} />
        <Features items={prestations} />
      </section>
      <CallToAction />
    </div>
  );
};

PrestationsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  background_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  prestations: PropTypes.array,
  contentComponent: PropTypes.func,
};

const PrestationsPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout>
      <SEO
        title={
          page.frontmatter.meta_title
            ? page.frontmatter.meta_title
            : page.frontmatter.title
        }
        description={
          page.frontmatter.meta_description
            ? page.frontmatter.meta_description
            : ""
        }
      />
      <PrestationsPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        backgroundImageFile={page.frontmatter.background_image}
        content={page.html}
        prestations={page.frontmatter.prestations_block.prestations}
      />
    </Layout>
  );
};

PrestationsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrestationsPage;

export const pageQuery = graphql`
         query PrestationsPageTemplate($id: String!) {
           markdownRemark(id: { eq: $id }) {
             html
             frontmatter {
               title
               background_image {
                 childImageSharp {
                   fluid(maxWidth: 1024, quality: 90) {
                     ...GatsbyImageSharpFluid_withWebp
                   }
                 }
               }
               prestations_block {
                 prestations {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 500, quality: 80) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                   title
                   text
                   link
                 }
               }
               meta_title
               meta_description
             }
           }
         }
       `;
